import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'

/**
 * 四码统计
 */
export const keepStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/fundSupervision/keepStatistics',
        params
    })
}

/**
 * 监管资金占比情况:资金占比
 */
export const moneyStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/fundSupervision/moneyStatistics',
        params
    })
}

/**
 * 监管资金占比情况:商户占比
 */
export const busStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/fundSupervision/busStatistics',
        params
    })
}

/**
 * 监管资金新增趋势
 */
export const fundSupervisionAdd = (params) => {
    return $api.post({
        url: baseURL + '/api/fundSupervision/fundSupervisionAdd',
        params
    })
}

/**
 * 监管资金累计趋势
 */
export const fundSupervisionCumulative = (params) => {
    return $api.post({
        url: baseURL + '/api/fundSupervision/fundSupervisionCumulative',
        params
    })
}

/**
 * 监管资金按城市/地区统计
 */
export const fundSupervisionCityStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/fundSupervision/fundSupervisionCityStatistics',
        params
    })
}

/**
 * 监管资金按领域/行业统计
 */
export const fundIndustryStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/fundSupervision/fundIndustryStatistics',
        params
    })
}


//还没调
/**
 * 监管资金占比情况:应存管发卡银行/商户占比
 */
export const bankStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/fundSupervision/bankStatistics',
        params
    })
}
/**
 * 监管资金占比情况:保险资金各银行/商户占比
 */
export const insuranceBankStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/fundSupervision/insuranceBankStatistics',
        params
    })
}

/**
 * 监管商户变化趋势
 */
export const changingTrendOfBus = (params) => {
    return $api.get({
        url: baseURL + '/api/fundSupervision/changingTrendOfBus',
        params
    })
}

/**
 * 资金监管过程及变化情况
 */
export const processAndChanging = (params) => {
    return $api.get({
        url: baseURL + '/api/fundSupervision/processAndChanging',
        params
    })
}


/**
 * 监管资金占比情况:信托资金各银行/商户占比
 */
export const trustStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/fundSupervision/trustStatistics',
        params
    })
}
/**
 * 监管资金占比情况:担保资金各银行/商户占比
 */
export const guaranteeBankStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/fundSupervision/guaranteeBankStatistics',
        params
    })
}


