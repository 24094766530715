<template>
  <div class="public">
    <div class="container HomeSupervise">
      <!-- 四色码 -->
<!--      <div class="homeSupervise-title">存管商户卡项交易总额：<span>{{sumTotalMoney}}</span>元 | 单项目：<span>{{totalCardAmountSingle}}</span>元</div>-->
      <div class="idiot-one">
        <div class="box" v-for="item in forCodeData.slice(0,2)" :key="item.type">
          <div class="fr box-right">
            <div class="box-header">
              {{
                item.type == 1
                    ? "存管商户卡项交易"
                    : item.type == 2
                    ? "投保商户卡项交易"
                    : "存管商户卡项交易"
              }}
              <span>{{ item.totalMoney }}</span
              > 元
              <p>单项<span>{{item.totalCardAmountSingle}}</span> 元</p>
            </div>
            <div class="box-bar">
              <div
                  class="box-bar-child"
                  :style="{ width: item.ratio + '%' }"
              ></div>
            </div>
            <div class="box-boxs">
              <div class="box-one">
                <div>
                  {{
                    item.type == 1
                        ? "存管金额"
                        : item.type == 2
                        ? "保险金额"
                        : "存管金额"
                  }}
                </div>
                <div>
                  <span>{{
                    item.type == 1 ? item.keepMoney : item.totalMoney
                  }}</span
                  > 元
                </div>
              </div>
              <div class="box-one">
                <div>
                  {{
                    item.type == 1
                        ? "实际存管率"
                        : item.type == 2
                        ? "承保率"
                        : "实际存管率"
                  }}
                </div>
                <div>
                  <span>{{ item.ratio || "0.00" }}</span
                  >%
                </div>
              </div>
              <div class="box-one">
                <div>
                  {{
                    item.type == 1
                        ? "提现资金"
                        : item.type == 2
                        ? "预付交易额"
                        : "提现资金"
                  }}
                </div>
                <div>
                  <span>{{
                    item.type == 1 ? item.cashMoney : item.totalMoney
                  }}</span
                  > 元
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="idiot-one idiot-newone">
        <div class="box" v-for="item in forCodeData.slice(2)" :key="item.type">
          <div class="fr box-right">
            <div class="box-header">
              {{
                item.type == 3
                  ? "信托商户卡项交易"
                  : item.type == 4
                  ? "担保商户卡项交易"
                  : ""
              }}
              <span>{{ item.totalMoney }}</span
              > 元
              <p>单项<span>{{item.totalCardAmountSingle}}</span> 元</p>
            </div>
            <div class="box-bar">
              <div class="box-bar-child" :style="{ width: 0 + '%' }"></div>
            </div>
            <div class="box-boxs">
              <div class="box-one">
                <div>
                  {{
                    item.type == 3
                      ? "托管金额"
                      : item.type == 4
                      ? "担保金额"
                      : "存管金额"
                  }}
                </div>
                <div>
                   <span>{{
                       item.type == 3 ? item.keepMoney : item.totalMoney
                     }}</span
                   > 元
                </div>
              </div>
              <div class="box-one">
                <div>
                  {{
                    item.type == 3
                      ? "实际托管率"
                      : item.type == 4
                      ? "担保率"
                      : "实际存管率"
                  }}
                </div>
                <div>
                   <span>{{ item.ratio || "0.00" }}</span
                   >%
                </div>
              </div>
              <div class="box-one">
                <div>
                  {{
                    item.type == 3
                      ? "提现资金"
                      : item.type == 4
                      ? "预付交易额"
                      : "提现资金"
                  }}
                </div>
                <div>
                 <span>{{
                     item.type == 3 ? item.cashMoney : item.totalMoney
                   }}</span
                 > 元
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 监管资金占比情况 饼图 -->
      <div class="idiot-two echarts pie-box">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>监管{{ typeName }}分布情况</span>
            <label class="txt"
              >银行存管：<i>{{ statisticsData[0].totalRatio + "%" }}</i></label
            >
            <label class="txt"
              >保证保险：<i>{{ statisticsData[1].totalRatio + "%" }}</i></label
            >
              <label class="txt"
            >信托：<i>{{ statisticsData[2].totalRatio + "%" }}</i></label
            >
            <label class="txt"
            >担保：<i>{{ statisticsData[3].totalRatio + "%" }}</i></label
            >

          </div>
          <!-- <el-button @click="toOnePie()" v-show="isTowPie" type="text" style="position: absolute;top: 6px;right: 130px;font-size: 14px;">返回</el-button> -->
          <el-radio-group v-model="tabPosition1" class="tab-select-btn">
            <el-radio-button label="1">资金</el-radio-button>
            <el-radio-button label="2">商户</el-radio-button>
          </el-radio-group>
        </div>
        <div class="clearfix pie-chart-box">
          <div class="clearfix">
            <div class="txt" v-for="item in statisticsData.slice(0,2)" :key="item.name">
              <span>{{ item.name }}</span
              ><span
                >{{ tabPosition1 == 1 ? item.money : item.num
                }}<i v-if="tabPosition1 == 2">家</i></span
              >
            </div>
          </div>
          <div class="pie-charts">
            <PieChart
              :chartData="pieChartData"
              :titleName="statisticsData[0].totalRatio + '%'"
            ></PieChart>
          </div>
          <div class="pie-charts">
            <PieChart
              :chartData="pieChartData1"
              :titleName="statisticsData[1].totalRatio + '%'"
            ></PieChart>
          </div>
          <div class="clearfix">
            <div class="txt" v-for="(item,ind) in statisticsData.slice(2)" :key="item.name">
              <span>{{ item.name }}</span
              ><span
            >{{ tabPosition1 == 1 ? item.money : item.num
              }}<i v-if="tabPosition1 == 2">家</i></span
            >
            </div>
          </div>
          <div class="pie-charts">
            <PieChart
                :chartData="pieChartData2"
                :titleName="statisticsData[2].totalRatio + '%'"
            ></PieChart>
          </div>
          <div class="pie-charts">
            <PieChart
                :chartData="pieChartData3"
                :titleName="statisticsData[3].totalRatio + '%'"
            ></PieChart>
          </div>
        </div>
      </div>
      <!-- 资金监管过程及变化情况  -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>资金监管过程及变化情况</span>
          </div>
          <!-- <el-button @click="toOnePie()" v-show="isTowPie" type="text" style="position: absolute;top: 6px;right: 130px;font-size: 14px;">返回</el-button> -->
          <el-radio-group v-model="tabPosition7" class="tab-select-btn">
            <el-radio-button label="1">存管</el-radio-button>
            <el-radio-button label="2">保险</el-radio-button>
            <el-radio-button label="3">信托</el-radio-button>
            <el-radio-button label="4">担保</el-radio-button>
          </el-radio-group>
        </div>
        <div class="element-tab">
          <DateSelect @dateChange="dateChange"></DateSelect>
          <HomeSuperviseItem
            v-loading="itemLoading"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :itemList="itemList"
            :type="tabPosition7"
          ></HomeSuperviseItem>
        </div>
      </div>
      <!-- 监管资金新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>监管{{ typeName2 }}新增变化趋势</span>
          </div>
          <el-radio-group v-model="tabPosition2" class="tab-select-btn">
            <el-radio-button label="0">资金</el-radio-button>
            <el-radio-button label="1">商户</el-radio-button>
          </el-radio-group>
          <div class="echarts-but">
            <el-radio-group v-model="radio1">
              <el-radio-button
                v-for="item in radioData1"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <Line-chart
            v-loading="lineChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :chartData="lineChartData"
            :legendData="legendData2"
            :units="unitData2"
            :yAxis="['']"
            :yAxisIndex="['0']"
            :type="radio1"
          ></Line-chart>
          <!-- <BarChart :chartData="barChartData" :legendData="['存管', '保险']"></BarChart> -->
        </div>
      </div>
      <!-- 监管资金变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>监管{{typeName3}}累计变化趋势</span>
          </div>
          <el-radio-group v-model="tabPosition3" class="tab-select-btn">
            <el-radio-button label="1">存管</el-radio-button>
            <el-radio-button label="2">保险</el-radio-button>
            <el-radio-button label="3">信托</el-radio-button>
            <el-radio-button label="4">担保</el-radio-button>
          </el-radio-group>
          <div class="echarts-but">
            <el-radio-group v-model="radio2">
              <el-radio-button
                v-for="item in radioData1"
                :label="item.value"
                :key="item.value"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <div class="charts">
          <LinebarChart
            :chartData="barChartData"
            v-loading="barChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="legendData"
            :units="unitData"
            :yAxis="['', '%']"
            :yAxisIndex="['0', '0', '1']"
            :istrue="this.tabPosition3 + ''"
            :type="radio2"
          ></LinebarChart>
        </div>
      </div>
      <!-- 监管资金按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>监管{{ typeName4 }}地区分布数量统计</span>
          </div>
          <el-radio-group
            v-model="tabPosition4"
            class="tab-select-btn right-size"
          >
            <el-radio-button label="0">资金</el-radio-button>
            <el-radio-button label="1">商户</el-radio-button>
          </el-radio-group>
          <div class="h-selct">
            <el-select v-model="hairpinType1" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in options"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
          >
            <el-table-column
              prop="regionName"
              :key="0"
              label="地区"
              sortable
              width="290"
            >
            </el-table-column>
            <template v-if="tabPosition4 == 0">
              <el-table-column
                prop="money"
                :key="1"
                label="监管资金金额"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.money }}</span
                  ><i
                    :class="
                      scope.row.moneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.moneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.moneySign > 0
                        ? "+" + scope.row.moneySign
                        : scope.row.moneySign < 0
                        ? "" + scope.row.moneySign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="custodyMoney"
                :key="2"
                label="存管商户发卡金额"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.custodyMoney }}</span
                  ><i
                    :class="
                      scope.row.custodyMoneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.custodyMoneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.custodyMoneySign > 0
                        ? "+" + scope.row.custodyMoneySign
                        : scope.row.custodyMoneySign < 0
                        ? "" + scope.row.custodyMoneySign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                :key="3"
                label="保险商户发卡金额"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.insuranceMoney }}</span
                  ><i
                    :class="
                      scope.row.insuranceMoneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.insuranceMoneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.insuranceMoneySign > 0
                        ? "+" + scope.row.insuranceMoneySign
                        : scope.row.insuranceMoneySign < 0
                        ? "" + scope.row.insuranceMoneySign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                  prop="trustMoney"
                  :key="4"
                  label="信托商户发卡金额"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.trustMoney }}</span
                  ><i
                    :class="
                      scope.row.trustMoneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.trustMoneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.trustMoneySign > 0
                        ? "+" + scope.row.trustMoneySign
                        : scope.row.trustMoneySign < 0
                        ? "" + scope.row.trustMoneySign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="guaranteeMoney"
                  :key="5"
                  label="担保商户发卡金额"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.guaranteeMoney }}</span
                  ><i
                    :class="
                      scope.row.guaranteeMoneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.guaranteeMoneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.guaranteeMoneySign > 0
                        ? "+" + 0
                        : scope.row.guaranteeMoneySign < 0
                        ? "" + scope.row.guaranteeMoneySign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
            </template>
            <template v-if="tabPosition4 == 1">
              <el-table-column prop="num" :key="1" label="总商户数" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.num }}</span
                  ><i
                    :class="
                      scope.row.numSign > 0
                        ? 'num-badge-blue'
                        : scope.row.numSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.numSign > 0
                        ? "+" + scope.row.numSign
                        : scope.row.numSign < 0
                        ? "" + scope.row.numSign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="custodyNum"
                :key="2"
                label="存管商户数"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.custodyNum }}</span
                  ><i
                    :class="
                      scope.row.custodyNumSign > 0
                        ? 'num-badge-blue'
                        : scope.row.custodyNumSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.custodyNumSign > 0
                        ? "+" + scope.row.custodyNumSign
                        : scope.row.custodyNumSign < 0
                        ? "" + scope.row.custodyNumSign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceNum"
                :key="3"
                label="保险商户数"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.insuranceNum }}</span
                  ><i
                    :class="
                      scope.row.insuranceNumSign > 0
                        ? 'num-badge-blue'
                        : scope.row.insuranceNumSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.insuranceNumSign > 0
                        ? "+" + scope.row.insuranceNumSign
                        : scope.row.insuranceNumSign < 0
                        ? "" + scope.row.insuranceNumSign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                  prop="trustNum"
                  :key="4"
                  label="信托商户数"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.trustNum }}</span
                  ><i
                    :class="
                      scope.row.trustNumSign > 0
                        ? 'num-badge-blue'
                        : scope.row.trustNumSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.trustNumSign > 0
                        ? "+" + scope.row.trustNumSign
                        : scope.row.trustNumSign < 0
                        ? "" + scope.row.trustNumSign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceNum"
                  :key="5"
                  label="担保商户数"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.guaranteeNum }}</span
                  ><i
                    :class="
                      scope.row.guaranteeNumSign > 0
                        ? 'num-badge-blue'
                        : scope.row.guaranteeNumSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.guaranteeNumSign > 0
                        ? "+" + scope.row.guaranteeNumSign
                        : scope.row.guaranteeNumSign < 0
                        ? "" + scope.row.guaranteeNumSign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
            </template>

          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
              >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 监管资金按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>监管{{ typeName5 }}领域/行业分布数量统计</span>
          </div>
          <el-radio-group
            v-model="tabPosition5"
            class="tab-select-btn right-size"
          >
            <el-radio-button label="0">资金</el-radio-button>
            <el-radio-button label="1">商户</el-radio-button>
          </el-radio-group>
          <div class="h-selct">
            <el-select v-model="hairpinType2" placeholder="请选择" size="mini">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="item in options"
                :key="item.haripinType"
                :label="item.name"
                :value="item.haripinType"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll2"
            v-loading="loading2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            row-key="industryId"
            lazy
            :load="loadNextNode2"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort2"
          >
            <el-table-column prop="industryName" :key="0" label="行业" sortable>
            </el-table-column>
            <template v-if="tabPosition5 == 0">
              <el-table-column
                prop="money"
                :key="1"
                label="监管资金金额"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.money }}</span
                  ><i
                    :class="
                      scope.row.moneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.moneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.moneySign > 0
                        ? "+" + scope.row.moneySign
                        : scope.row.moneySign < 0
                        ? "" + scope.row.moneySign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="custodyMoney"
                :key="2"
                label="存管商户发卡金额"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.custodyMoney }}</span
                  ><i
                    :class="
                      scope.row.custodyMoneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.custodyMoneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.custodyMoneySign > 0
                        ? "+" + scope.row.custodyMoneySign
                        : scope.row.custodyMoneySign < 0
                        ? "" + scope.row.custodyMoneySign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceMoney"
                :key="3"
                label="保险商户发卡金额"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.insuranceMoney }}</span
                  ><i
                    :class="
                      scope.row.insuranceMoneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.insuranceMoneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.insuranceMoneySign > 0
                        ? "+" + scope.row.insuranceMoneySign
                        : scope.row.insuranceMoneySign < 0
                        ? "" + scope.row.insuranceMoneySign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                  prop="trustMoney"
                  :key="4"
                  label="信托商户发卡金额"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.trustMoney }}</span
                  ><i
                    :class="
                      scope.row.trustMoneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.trustMoneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.trustMoneySign > 0
                        ? "+" + scope.row.trustMoneySign
                        : scope.row.trustMoneySign < 0
                        ? "" + scope.row.trustMoneySign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="guaranteeMoney"
                  :key="5"
                  label="担保商户发卡金额"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.guaranteeMoney }}</span
                  ><i
                    :class="
                      scope.row.guaranteeMoneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.guaranteeMoneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.guaranteeMoneySign > 0
                        ? "+" + 0
                        : scope.row.guaranteeMoneySign < 0
                        ? "" + scope.row.guaranteeMoneySign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
            </template>
            <template v-if="tabPosition5 == 1">
              <el-table-column prop="num" :key="1" label="总商户数" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.num }}</span
                  ><i
                    :class="
                      scope.row.numSign > 0
                        ? 'num-badge-blue'
                        : scope.row.numSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.numSign > 0
                        ? "+" + scope.row.numSign
                        : scope.row.numSign < 0
                        ? "" + scope.row.numSign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="custodyNum"
                :key="2"
                label="存管商户数"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.custodyNum }}</span
                  ><i
                    :class="
                      scope.row.custodyNumSign > 0
                        ? 'num-badge-blue'
                        : scope.row.custodyNumSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.custodyNumSign > 0
                        ? "+" + scope.row.custodyNumSign
                        : scope.row.custodyNumSign < 0
                        ? "" + scope.row.custodyNumSign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="insuranceNum"
                :key="3"
                label="保险商户数"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.insuranceNum }}</span
                  ><i
                    :class="
                      scope.row.insuranceNumSign > 0
                        ? 'num-badge-blue'
                        : scope.row.insuranceNumSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.insuranceNumSign > 0
                        ? "+" + scope.row.insuranceNumSign
                        : scope.row.insuranceNumSign < 0
                        ? "" + scope.row.insuranceNumSign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                  prop="trustNum"
                  :key="4"
                  label="信托商户数"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.trustNum }}</span
                  ><i
                    :class="
                      scope.row.trustNumSign > 0
                        ? 'num-badge-blue'
                        : scope.row.trustNumSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.trustNumSign > 0
                        ? "+" + scope.row.trustNumSign
                        : scope.row.trustNumSign < 0
                        ? "" + scope.row.trustNumSign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceNum"
                  :key="5"
                  label="担保商户数"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.guaranteeNum }}</span
                  ><i
                    :class="
                      scope.row.guaranteeNumSign > 0
                        ? 'num-badge-blue'
                        : scope.row.guaranteeNumSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.guaranteeNumSign > 0
                        ? "+" + scope.row.guaranteeNumSign
                        : scope.row.guaranteeNumSign < 0
                        ? "" + scope.row.guaranteeNumSign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
            </template>

          </el-table>
          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
              >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 金融机构业绩排行 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>金融机构业绩排行</span>
          </div>
          <el-radio-group v-model="tabPosition6" class="tab-select-btn">
            <el-radio-button label="0">银行</el-radio-button>
            <el-radio-button label="1">保险</el-radio-button>
            <el-radio-button label="2">信托</el-radio-button>
            <el-radio-button label="3">担保</el-radio-button>
          </el-radio-group>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableData3"
            style="width: 100%"
            row-key="id"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="date" :label="tabPosition6 == 2 ? '信托公司' : tabPosition6 == 3 ? '担保公司' : '银行'" sortable>
            </el-table-column>
            <el-table-column prop="name" label="开户业绩" sortable>
            </el-table-column>
            <el-table-column prop="address" :label="tabPosition6 == 2 ? '托管业绩' : tabPosition6 == 3 ? '担保业绩' : '存管业绩'" sortable>
            </el-table-column>
            <el-table-column prop="address" label="普惠金融" sortable>
            </el-table-column>
            <el-table-column prop="address" label="服务评价" sortable>
            </el-table-column>
          </el-table>
          <div class="page-btn">
            <el-button type="text"
              >收起全部<i
                class="el-icon-d-arrow-left el-icon--right rotate-top"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line2"; // 四码折线图
import PieChart from "@/components/charts/PieSupervise"; //饼图
import PieSmallChart from "@/components/charts/pieSmall"; //饼图
import LinebarChart from "@/components/charts/linebardz1"; // 折线+柱状+滚动条
import BarChart from "@/components/charts/bar";
import DateSelect from "@/components/dateSelect/dateSelect";
import HomeSuperviseItem from "@/components/homeSupervise-item/homeSupervise-item";
import {
  keepStatistics,
  moneyStatistics,
  busStatistics,
  fundSupervisionAdd,
  changingTrendOfBus,
  fundSupervisionCityStatistics,
  fundIndustryStatistics,
  bankStatistics,
  insuranceBankStatistics,
  guaranteeBankStatistics,
  trustStatistics,
  processAndChanging,
} from "@/api/homesupervise";
import { cardType } from "@/api/common";

export default {
  name: "HomeSupervise",
  props: ["regionName", "cityName", "flag", "regionId", "regionType"],
  data() {
    return {
      lineChart1: false,
      barChart2: false,
      itemLoading: false,
      // totalMoney: "0",
      outing: [],
      options: [],
      forCodeData: [],
      sumTotalMoney:0,
      totalCardAmountSingle:0,
      typeName: "资金",
      typeName2: "资金",
      typeName3: "存管",
      typeName4: "资金",
      typeName5: "资金",
      tabPosition1: "1",
      tabPosition2: 0,
      tabPosition3: 1,
      tabPosition4: 0,
      tabPosition5: 0,
      tabPosition6: 0,
      tabPosition7: 1,
      isTowPie: false,
      statisticsData: [{
        totalRatio: "0.00"
      },{
        totalRatio: "0.00"
      },{
        totalRatio: "0.00"
      },{
        totalRatio: "0.00"
      }],
      cunguan: "",
      baoxian: "",
      pieChartData: {
        seriesData: [],
      },
      pieChartData1: {
        seriesData: [],
      },
      pieChartData2: {
        seriesData: [],
      },
      pieChartData3: {
        seriesData: [],
      },
      barChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "存管金额",
            type:"bar",
            value: [],
          },
          {
            name: "提现金额",
            type:"bar",
            value: [],
          },
          {
            name: "实际存管率",
            type:"line",
            value: [],
          },
        ],
      },
      legendData: ["存管金额", "提现金额", "实际存管率"],
      unitData: ["元", "元"],
      radio1: "1",
      radio2: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      lineChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "存管商户发卡金额",
            data: [],
          },
          {
            name: "保险商户发卡金额",
            data: [],
          },
          {
            name: "信托商户发卡金额",
            data: [],
          },
          {
            name: "担保商户发卡金额",
            data: [],
          },
        ],
      },
      legendData2: [
        "存管商户发卡金额",
        "保险商户发卡金额",
        "信托商户发卡金额",
        "担保商户发卡金额",
      ],
      unitData2: ["元", "元", "元", "元"],
      hairpinType1: "",
      showAll: false,
      tableDataAll: [],
      tableData1: [],
      hairpinType2: "",
      showAll2: false,
      loading1: false,
      loading2: false,
      tableDataAll2: [],
      tableData2: [],
      showAll3: false,
      tableDataAll3: [],
      tableData3: [],
      pieSmallChartData: {
        seriesData: [
          {
            name: "保险商户发卡金额",
            value: "",
          },
        ],
      },
      pieSmallChartData2: {
        seriesData: [
          {
            name: "保险商户发卡金额",
            value: "",
          },
        ],
      },
      itemList: [
        {
          name: "T+0",
          tradeMoney: "0",
          depositScale: "0",
          cashScale: "0",
        },
        {
          name: "T+1",
          shouldDepositMoney: "0",
          shouldDepositScale: "0",
          realityDepositMoney: "0",
          realityDepositScale: "0",
          cashScale: "0",
        },
        {
          name: "TN-1",
          shouldDepositMoney: "0",
          shouldDepositScale: "0",
          realityDepositMoney: "0",
          realityDepositScale: "0",
          cashScale: "0",
        },
      ],
      date: this.dataAdd0(new Date().getTime()),
    };
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        cityName: this.cityName,
        areaName: this.areaName,
        flag: this.flag,
        regionId: this.regionId,
        regionType: this.regionType,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    tabPosition1: function (val) {
      //console.log(val)
      //this.isTowPie = false
      this.getBankStatistics();
      this.getInsuranceBankStatistics();
      this.getGuaranteeBankStatistics();
      this.getTrustStatistics();
      if (val == 1) {
        this.moneyStatistics();
      } else {
        this.busStatistics();
      }
      this.typeName = val == 1 ? "资金" : "商户";
    },
    radio1: function (val) {
      this.fundSupervisionAdd();
    },
    tabPosition2: function (val) {
      this.fundSupervisionAdd();
      this.legendData2 =
        val == 0
          ? [
              "存管商户发卡金额",
              "保险商户发卡金额",
              "信托商户发卡金额",
              "担保商户发卡金额",
            ]
          : ["存管商户数量", "保险商户数量","信托商户数量","担保商户数量"];
      this.unitData2 = val == 0 ? ["元", "元", "元", "元"] : ["家", "家","家", "家"];
      this.typeName2 = val == 0 ? "资金" : "商户";
    },
    radio2: function (val) {
      this.changingTrendOfBus();
    },
    tabPosition3: function (val) {
      this.changingTrendOfBus();

      // this.legendData =
      //   val == 1
      //     ? ["存管金额", "提现金额", "实际存管率"]
      //     : ["投保商户交易总额", "保险金额", "承保率"];
      // this.unitData = val == 1 ? ["元", "元"] : ["家", "家"];
      // this.typeName3 = val == 1 ? "资金" : "商户";
      this.legendData =
        val == 1
          ? ["存管金额", "提现金额", "实际存管率"]
          : val == 2
          ? ["投保商户交易总额", "保险金额", "承保率"]
          : val == 3
          ? ["托管金额", "提现金额", "实际存管率"]
          : ["担保商户交易总额", "担保金额", "担保率"];
      this.unitData =
        val == 1
          ? ["元", "元"]
          : val == 2
          ? ["元", "元"]
          : val == 3
          ? ["元", "元"]
          : ["元", "元"];
        this.typeName3 = val == 1 ? "存管" :val == 2 ?"投保":val == 3 ?"托管": "担保";
    },
    tabPosition4: function (val) {
      this.fundSupervisionCityStatistics();
      this.typeName4 = val == 0 ? "资金" : "商户";
    },
    hairpinType1: function (val) {
      this.fundSupervisionCityStatistics();
    },
    tabPosition5: function (val) {
      this.fundIndustryStatistics();
      this.typeName5 = val == 0 ? "资金" : "商户";
    },
    tabPosition7: function (val) {
      this.processAndChanging();
    },
    hairpinType2: function (val) {
      this.fundIndustryStatistics();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  mounted() {
    if (sessionStorage.getItem("initParams") === "init") {
      this.intData();
    }
  },
  beforeDestroy() {
    window.sessionStorage.setItem("initParams", "init");
  },
  methods: {
    //日期补0，传时间对象或时间戳
    dataAdd0(date) {
      var year = new Date(date).getFullYear() + "-";
      var month = (new Date(date).getMonth() + 1 + "").padStart(2, "0") + "-";
      var day = (new Date(date).getDate() + "").padStart(2, "0");
      return year + month + day;
    },
    intData() {
      this.cardType();
      this.keepStatistics();
      this.moneyStatistics();
      this.fundSupervisionAdd();
      this.changingTrendOfBus();
      this.fundSupervisionCityStatistics();
      this.fundIndustryStatistics();
      this.getBankStatistics();
      this.getInsuranceBankStatistics();
      this.getGuaranteeBankStatistics();
      this.getTrustStatistics();
      this.processAndChanging();
    },
    cardType() {
      cardType().then((res) => {
        this.options = res.data;
      });
    },
    keepStatistics() {
      keepStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          console.log(res.data);
          this.forCodeData = res.data;
          this.sumTotalMoney = res.data[0].sumTotalMoney
          this.totalCardAmountSingle = res.data[0].totalCardAmountSingle
          // this.totalMoney = ((res.data[0].totalMoney - 0) + (res.data[1].totalMoney - 0)).toFixed(2)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBankStatistics() {
      let _this = this;
      bankStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: _this.tabPosition1,
      }).then((res) => {
        //console.log('应存管发卡银行/商户占比',res)
        let arr = [];
        _this.pieChartData.seriesData = [];
        res.data.forEach((item) => {
          if (_this.tabPosition1 == 1) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
              });
            }
          } else if (_this.tabPosition1 == 2) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
              });
            }
          }
          _this.pieChartData.seriesData = arr;
          // console.log(arr)
        });
      });
    },
    getInsuranceBankStatistics() {
      let _this = this;
      insuranceBankStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: _this.tabPosition1,
      }).then((res) => {
        //console.log('保险资金各银行/商户占比',res)
        let arr = [];
        res.data.forEach((item) => {
          if (_this.tabPosition1 == 1) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
              });
            }
          } else if (_this.tabPosition1 == 2) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
              });
            }
          }
          _this.pieChartData1.seriesData = arr;
        });
      });
    },
    getTrustStatistics() {
      let _this = this;
      trustStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: _this.tabPosition1,
      }).then((res) => {
        //console.log('保险资金各银行/商户占比',res)
        let arr = [];
        res.data.forEach((item) => {
          if (_this.tabPosition1 == 1) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
              });
            }
          } else if (_this.tabPosition1 == 2) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
              });
            }
          }
          _this.pieChartData2.seriesData = arr;
        });
      });
    },
    getGuaranteeBankStatistics() {
      let _this = this;
      guaranteeBankStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: _this.tabPosition1,
      }).then((res) => {
        //console.log('保险资金各银行/商户占比',res)
        let arr = [];
        res.data.forEach((item) => {
          if (_this.tabPosition1 == 1) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
              });
            }
          } else if (_this.tabPosition1 == 2) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
              });
            }
          }
          _this.pieChartData3.seriesData = arr;
        });
      });
    },
    toOnePie() {
      if (this.tabPosition1 == 1) {
        this.moneyStatistics();
      } else if (this.tabPosition1 == 2) {
        this.busStatistics();
      }
      this.isTowPie = false;
    },
    moneyStatistics() {
      let _this = this;
      moneyStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('资金占比',res)
        _this.cunguan = 0;
        _this.baoxian = 0;
        res.data.forEach((item) => {
          if (item.name.indexOf("存管") != -1) {
            _this.cunguan = item.totalRatio;
          } else if (item.name.indexOf("保险") != -1) {
            _this.baoxian = item.totalRatio;
          }
        });
        _this.statisticsData = res.data;
      });
    },
    busStatistics() {
      let _this = this;
      busStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('商户占比',res)
        res.data.forEach((item) => {
          if (item.name.indexOf("存管") != -1) {
            _this.cunguan = item.totalRatio;
          } else if (item.name.indexOf("保险") != -1) {
            _this.baoxian = item.totalRatio;
          }
        });
        _this.statisticsData = res.data;
      });
    },
    // fundSupervisionAdd() {
    //   fundSupervisionAdd({
    changingTrendOfBus() {
      this.barChart2 = true;
      changingTrendOfBus({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio2,
        reqType: this.tabPosition3,
      }).then((res) => {
        // console.log('监管资金累计变化趋势',res)
        this.barChart2 = false;
        if (res.data != null) {
          let amountOfDeposit = [],
            amountOfTrade = [],
            amountOfWithdraw = [],
            rateOfDeposit = [],
            amountOfInsured = [],
            amountOfTradeb = [],
            rateOfUnderwrite = [];
          res.data.dataList.map((v, i) => {
            amountOfDeposit.push(v.amountOfDeposit);
            amountOfTrade.push(v.amountOfTrade);
            amountOfWithdraw.push(v.amountOfWithdraw);
            rateOfDeposit.push(v.rateOfDeposit);
            amountOfInsured.push(v.amountOfInsured);
            amountOfTradeb.push(v.amountOfTradeb);
            rateOfUnderwrite.push(v.rateOfUnderwrite);
          });

          let newdata = [];
          res.data.xlist.map((v, i) => {
            newdata.push("0.00");
          });
          this.barChartData = {
            xAxisData: res.data.xlist,
            seriesData: [
              {
                name:
                  this.tabPosition3 == 1
                    ? "存管金额"
                    : this.tabPosition3 == 2
                    ? "投保商户交易总额"
                    : this.tabPosition3 == 3
                    ? "托管金额"
                    : this.tabPosition3 == 4
                    ? "担保商户交易总额"
                    : "",
                type: "bar",
                data:
                  this.tabPosition3 == 1
                    ? amountOfDeposit
                    : this.tabPosition3 == 2
                    ? amountOfTradeb
                    : this.tabPosition3 == 3
                    ? newdata
                    : this.tabPosition3 == 4
                    ? newdata
                    : [],
              },
              {
                name:
                  this.tabPosition3 == 1
                    ? "提现金额"
                    : this.tabPosition3 == 2
                    ? "保险金额"
                    : this.tabPosition3 == 3
                    ? "提现金额"
                    : this.tabPosition3 == 4
                    ? "担保金额"
                    : "",
                type: "bar",
                data:
                  this.tabPosition3 == 1
                    ? amountOfWithdraw
                    : this.tabPosition3 == 2
                    ? amountOfInsured
                    : this.tabPosition3 == 3
                    ? newdata
                    : this.tabPosition3 == 4
                    ? newdata
                    : [],
              },
              {
                name:
                  this.tabPosition3 == 1
                    ? "实际存管率"
                    : this.tabPosition3 == 2
                    ? "承保率"
                    : this.tabPosition3 == 3
                    ? "实际存管率"
                    : this.tabPosition3 == 4
                    ? "担保率"
                    : "",
                type: "line",
                data:
                  this.tabPosition3 == 1
                    ? rateOfDeposit
                    : this.tabPosition3 == 2
                    ? rateOfUnderwrite
                    : this.tabPosition3 == 3
                    ? newdata
                    : this.tabPosition3 == 4
                    ? newdata
                    : [],
              },
            ],
          };
        } else {
          this.barChartData = {
            xAxisData: [],
            seriesData: [
              {
                name:
                    this.tabPosition3 == 1
                        ? "存管金额"
                        : this.tabPosition3 == 2
                        ? "投保商户交易总额"
                        : this.tabPosition3 == 3
                            ? "托管金额"
                            : this.tabPosition3 == 4
                                ? "担保商户交易总额"
                                : "",
                type: "bar",
                data: [],
              },
              {
                name:
                    this.tabPosition3 == 1
                        ? "提现金额"
                        : this.tabPosition3 == 2
                        ? "保险金额"
                        : this.tabPosition3 == 3
                            ? "提现金额"
                            : this.tabPosition3 == 4
                                ? "担保金额"
                                : "",
                type: "bar",
                data: [],
              },
              {
                name:
                    this.tabPosition3 == 1
                        ? "实际存管率"
                        : this.tabPosition3 == 2
                        ? "承保率"
                        : this.tabPosition3 == 3
                            ? "实际存管率"
                            : this.tabPosition3 == 4
                                ? "担保率"
                                : "",
                type: "line",
                data: [],
              }
            ],
          };
        }
      });
    },
    fundSupervisionAdd() {
      this.lineChart1 = true;
      fundSupervisionAdd({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
      }).then((res) => {
        this.lineChart1 = false;
        let newdata = [];
        let newdata2=[];
        res.data.x_axis.map((v, i) => {
          newdata.push("0.00");
          newdata2.push(0)
        });

        // console.log("监管资金新增变化趋势", res);
        if (res.data != null) {
          this.lineChartData = {
            xAxisData: res.data.x_axis,
            seriesData: [
              {
                name:
                  this.tabPosition2 == 0
                    ? "存管商户发卡金额"
                    : this.tabPosition2 == 1
                    ? "存管商户数量"
                    : "",
                data:
                  this.tabPosition2 == 0
                    ? res.data.custodyMoney
                    : this.tabPosition2 == 1
                    ? res.data.custodyNum
                    : [],
              },
              {
                name:
                  this.tabPosition2 == 0
                    ? "保险商户发卡金额"
                    : this.tabPosition2 == 1
                    ? "保险商户数量"
                    : "",
                data:
                  this.tabPosition2 == 0
                    ? res.data.insurancesMoney
                    : this.tabPosition2 == 1
                    ? res.data.insurancesNum
                    : [],
              },
              {
                name:
                  this.tabPosition2 == 0
                    ? "信托商户发卡金额"
                    : this.tabPosition2 == 1
                    ? "信托商户数量"
                    : "",
                data:
                  this.tabPosition2 == 0
                    ? newdata
                    : this.tabPosition2 == 1
                    ? newdata2
                    : [],
              },
              {
                name:
                  this.tabPosition2 == 0
                    ? "担保商户发卡金额"
                    : this.tabPosition2 == 1
                    ? "担保商户数量"
                    : "",
                data:
                  this.tabPosition2 == 0
                    ? newdata
                    : this.tabPosition2 == 1
                    ? newdata2
                    : [],
              },
            ],
          };
        } else {
          this.lineChartData = {
            xAxisData: [],
            seriesData: [
              {
                name:
                  this.tabPosition2 == 0
                    ? "存管商户发卡金额"
                    : this.tabPosition2 == 1
                    ? "存管商户数量"
                    : "",
                data: [],
              },
              {
                name:
                  this.tabPosition2 == 0
                    ? "保险商户发卡金额"
                    : this.tabPosition2 == 1
                    ? "保险商户数量"
                    : "",
                data: [],
              },
            ],
          };
        }
      });
    },
    fundSupervisionCityStatistics() {
      let _this = this;
      this.loading1 = true;
      fundSupervisionCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按城市/地区统计',res)
        res.data.forEach((item) => {
          item.hasChildren = true;
        });
        this.loading1 = false;

         this.tableDataAll = [];
        this.$nextTick(() => {
          this.tableData1 = res.data || [];
          this.tableDataAll = this.tableData1.slice(0, 10);
          this.showAllData();
        });
      });
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    loadNextNode1(tree, treeNode, resolve) {
      // console.log(tree, 'treeNode, resolve')
      fundSupervisionCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
      }).then((res) => {
        // res.data = res.data.map(item => {
        //   item.hasChildren = true;
        //   return item
        // })
        // console.log('----------------------',res.data)
        resolve(res.data || []);
      });
    },
    fundIndustryStatistics() {
      this.loading2 = true;
      fundIndustryStatistics({
        hairpinType: this.hairpinType2,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按领域/行业统计',res)
        res.data.forEach((item) => {
          item.hasChildren = true;
        });
        this.loading2 = false;
        this.tableDataAll2 = [];
        this.$nextTick(() => {
          this.tableData2 = res.data || [];
          this.tableDataAll2 = this.tableData2.slice(0, 10);
          this.showAllData2();
        });
      });
    },
    processAndChanging() {
      this.itemLoading = true;
      processAndChanging({
        date: this.date,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        reqType: this.tabPosition7,
      }).then((res) => {
        this.itemLoading = false;
        if (this.tabPosition7 == 1) {
          this.itemList = [
            {
              name: "T+0",
              tradeMoney: res.data.amountOfTrade,
              depositScale: res.data.rateOfDeposit,
              cashScale: res.data.rateOfCash0,
            },
            {
              name: "T+1",
              shouldDepositMoney: res.data.yamountOfDeposit,
              shouldDepositScale: res.data.yrateOfDeposit,
              realityDepositMoney: res.data.samountOfDeposit,
              realityDepositScale: res.data.srateOfDeposit,
              cashScale: res.data.rateOfCash1,
            },
            {
              name: "TN-1",
              shouldDepositMoney: res.data.namountOfDeposit,
              shouldDepositScale: res.data.yrateOfDeposit,
              realityDepositMoney: res.data.nsamountOfDeposit,
              realityDepositScale: res.data.nsrateOfDeposit,
              cashScale: res.data.rateOfCashN,
            },
          ];
        } else if (this.tabPosition7 == 3) {
          this.itemList = [
            {
              name: "T+0",
              tradeMoney: 0,
              depositScale: 0,
              cashScale: 0,
            },
            {
              name: "T+1",
              shouldDepositMoney: 0,
              shouldDepositScale: 0,
              realityDepositMoney: 0,
              realityDepositScale: 0,
              cashScale: 0,
            },
            {
              name: "TN-1",
              shouldDepositMoney: 0,
              shouldDepositScale: 0,
              realityDepositMoney: 0,
              realityDepositScale: 0,
              cashScale: 0,
            },
          ];
        } else if (this.tabPosition7 == 4) {
          this.itemList = [
            {
              name: "T+0",
              tradeMoney: 0,
              rateOfUnderwrite0: 0,
              cashScale: 0,
            },
            {
              name: "T+1",
              amountOfInsured1: 0,
              rateOfUnderwrite1: 0,
              cashScale: 0,
            },
            {
              name: "TN-1",
              amountOfInsured1: 0,
              rateOfUnderwrite1: 0,
              cashScale: 0,
            },
          ];
        } else {
          this.itemList = [
            {
              name: "T+0",
              tradeMoney: res.data.amountOfTrade,
              rateOfUnderwrite0: res.data.rateOfUnderwrite0,
              cashScale: res.data.rateOfCash0,
            },
            {
              name: "T+1",
              amountOfInsured1: res.data.amountOfInsured1,
              rateOfUnderwrite1: res.data.rateOfUnderwrite1,
              cashScale: res.data.rateOfCash1,
            },
            {
              name: "TN-1",
              amountOfInsured1: res.data.amountOfInsured1,
              rateOfUnderwrite1: res.data.rateOfUnderwrite1,
              cashScale: res.data.rateOfCash1,
            },
          ];
        }
      });
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      fundIndustryStatistics({
        hairpinType: this.hairpinType2,
        industryId: tree.industryId,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按领域/行业统计-二级',res)
        resolve(res.data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
    dateChange(val) {
      console.log("日期变化", val);
      this.date = val;
      this.processAndChanging();
    },
  },
  components: {
    LineChart,
    PieChart,
    LinebarChart,
    BarChart,
    PieSmallChart,
    DateSelect,
    HomeSuperviseItem,
  },
};
</script>

<style lang="scss" scoped >
.HomeSupervise {
  .homeSupervise-title{
    margin: 0 30px 10px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: white;
    span{
      font-size: 32px;
      color: rgb(32, 141, 253);
      height: 35px;
      line-height: 30px;
      margin: 0px 5px;
    }
  }
  // 四色码
  .idiot-one {
    display: flex;
    justify-content: space-around;
    height: 210px;
    margin: 0 30px 30px 30px;
    border: 1px solid #17688d;
    background: linear-gradient(
      to bottom,
      rgba(43, 202, 255, 0.1),
      #0000,
      rgba(43, 202, 255, 0.1)
    );
    .box {
      width: 49.8%;
      text-align: center;
      .box-left {
        width: 26%;
        height: 140px;
      }
      .box-right {
        width: 100%;
        height: 100%;
        .box-header {
          text-align: left;
          color: oldlace;
          margin: 20px 0 10px;
          font-size: 18px;
          text-indent: 40px;
          span {
            font-size: 30px;
            color: #208dfd;
            margin-left: 10px;
          }
        }
        .box-bar {
          width: 464px;
          height: 22px;
          margin: 0 0 10px 40px;
          border: 1px solid #007dfd;
          background: rgba(0, 125, 253, 0.1);
          .box-bar-child {
            min-width: 30px;
            height: 100%;
            background: -webkit-linear-gradient(
              left,
              rgba(0, 125, 253, 0),
              rgba(0, 125, 253, 1)
            );
          }
        }
        .box-boxs {
          display: flex;
          justify-content: space-around;
          .box-one {
            min-width: 33%;
            font-size: 14px;
            color: #d2d2d2;
            text-align: center;
            margin-left: -30px;
            & > div {
              width: 100%;
            }
            span {
              // color: #18bbff;
              // min-width: 20px;
            }
          }
        }
      }
      &:nth-child(2) {
        .box-header span {
          color: #18bbff;
        }
        .box-bar {
          border: 1px solid #00feb3;
          background: rgba(0, 254, 179, 0.1);
          .box-bar-child {
            background: -webkit-linear-gradient(
              left,
              rgba(0, 254, 179, 0),
              rgba(0, 254, 179, 1)
            );
          }
        }
      }
    }
    .box:nth-child(1) {
      border-right: 1px solid transparent;
      border-image: linear-gradient(transparent, #17688d, transparent) 0 30;
    }
    .box:nth-child(2) {
      .box-right {
        .box-header {
          span {
            color: #5af4b1;
          }
        }
      }
    }
  }

  .idiot-newone {
    .box:nth-child(1) {
      .box-right {
        .box-header {
          span {
            color: #fdf949;
          }
        }
      }
      .box-bar {
        border: 1px solid #fdf949;
        background: rgba(253, 249, 73, 0.1);
        .box-bar-child {
          background: -webkit-linear-gradient(
            left,
            rgba(253, 249, 73, 0),
            rgba(253, 249, 73, 1)
          );
        }
      }
    }
     .box:nth-child(2) {
      .box-right {
        .box-header {
          span {
            color: #00fff6;
          }
        }
      }
      .box-bar {
        border: 1px solid #00fff6;
        background: rgba(0, 255, 246, 0.1);
        .box-bar-child {
          background: -webkit-linear-gradient(
            left,
            rgba(0, 255, 246, 0),
            rgba(0, 255, 246, 1)
          );
        }
      }
    }
  }
  .pie-box {
    background: url("../assets/imgs/pie-bg3.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0;
    .echarts-box {
      position: relative;
      .echarts-tit {
        .txt {
          margin-left: 30px;
          line-height: 40px;
          i {
            font-weight: bold;
          }
        }
      }
    }
    .pie-chart-box {
      .txt {
        width: 50%;
        float: left;
        box-sizing: border-box;
        text-align: center;
        margin-top: 50px;
        span:nth-child(1) {
          display: block;
          font-size: 18px;
        }
        span:nth-child(2) {
          display: block;
          font-size: 30px;
          color: #5bd5f7;
          i {
            font-size: 18px;
            margin-left: 10px;
          }
        }
        &:nth-child(2) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
              transparent,
              transparent,
              #17688d,
              #17688d,
              transparent,
              transparent
            )
            0 30 30 30;
          span:nth-child(2) {
            color: #208dfd;
          }
        }
      }
      .pie-charts {
        float: left;
        width: 50%;
        height: 300px;
        box-sizing: border-box;
        background: url("../assets/imgs/pie-bg2.png") no-repeat center center;
        background-size: 55%;
        &:nth-child(3),&:nth-child(6) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
              #17688d,
              #17688d,
              #17688d,
              transparent,
              transparent
            )
            30 30;
        }
      }
    }
  }
  .tab-select-btn {
    position: absolute;
    top: 6px;
    right: 0;
    &.right-size {
      right: 120px;
    }
  }
}
</style>
