<template>
  <div :style="{height:height,width:width}" class="bg" ref="chart"></div>
</template>
<script>
import {debounce} from 'lodash'
const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
        type:Object,
        default:{
            seriesData:[]
        }
    },
    titleName: {
        type: String,
        default: '占比'
      },
    width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
  },
  data() {
    return {
      colors: ['#208dfd','#18bbff','#26fff7', '#00ff5a','#c6ff00','#ffea00','#ffa200'],//['#00ff5a', '#26fff7']
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          this.initCharts();
        }
      },
      deep: true
    },
    titleName: function(val){
      if (val) {
        this.initCharts();
      }
    }
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);

    // let _this = this;
    // this.chart.on('click', function (params) {
    //   if(params.data.type == "supervise"){
    //     _this.$emit("getClickData",params.name)
    //   }else if(params.data.type == "place"){
    //     _this.$emit("getClickData",params.name)
    //   }
    // });
  },
  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);

      var rich = {
          value: {
              fontSize: 26,
              align: 'left'
          },
          name: {
              align: 'left',
              fontSize: 16,
              padding:[10,0],
              color:"#fff"
          },
          value2: {
              align: 'left',
              fontSize: 16,
              padding:[10,0],
              color:"#fff"
          },
          unit:{
            fontSize:16,
            align: 'left'
          }
      }
      this.chart.setOption( {
        title: {
            text: _this.titleName,
            x: 'center',
            y: 'center',
            itemGap: 20,
            textStyle : {
                color : '#6cfaff',
                fontFamily : '微软雅黑',
                fontSize : 36,
                fontWeight : 'bolder'
            }
        },
        series: [{
          startAngle:160,
            type: 'pie',
            minAngle:27,
            radius: ['52%', '62%'],
            avoidLabelOverlap:true,
            label: {
                normal: {
                  align: "left",
                  lineHeight: "20",
                  //formatter:'{c|blue}\n{d}%\n{b}',
                  formatter: function(params, ticket, callback) {
                      var percent = (params.percent).toFixed(2);
                      return '{value|' + params.value + '}{unit|'+ params.data.unit +'}\n{name|' + params.name + '}  {value2|' + params.data.ratio + '%}';
                  },
                  rich: rich
                },
            },
            labelLine: {
                normal: {
                  length: 28,
                    lineStyle: {
                        type:'dashed'
                    }
                }
            },
            itemStyle:{
                normal:{
                    borderColor:'#216ba1',
                    borderWidth:2,
                    color:function(params){
                      return _this.colors[params.dataIndex];
                    }
                }
            },
            data:_this.chartData.seriesData
        }]
      });
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
};
</script>
